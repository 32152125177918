import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './style.scss'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import "element-plus/dist/index.css"
import App from './App.vue'
import router from './router'
import * as Icons from "@element-plus/icons-vue"

const app = createApp(App)
app.use(ElementPlus,{locale : zhCn})
app.use(createPinia())
app.use(router)

app.mount('#app')

Object.keys(Icons).forEach(key=>{
    app.component(key, Icons[key as keyof typeof Icons])
})

