import axios from 'axios';
import { ApiBase } from "./base"
/* eslint-disable */

function credentialsPost (uri:string, body:any) {
  const url = ApiBase + uri
  const token:string = localStorage.getItem('token') || ""
  const headers:any = {'Content-Type': 'application/json'}
  if (token.length > 0)
    headers["Authorization"]=token
  return new Promise((resolve, reject)=>{
    axios.post(url, body, { headers: headers}).then(resp => {
      if (resp.data.code == 0) {
        resolve(resp.data)
      } else {
        reject(resp.data)
      }
    }).catch (()=> {
      reject({code:400, message:'网络访问失败',data:0})
    })
  })
}

function post(uri:string, body:any) {
  const url = ApiBase + uri
  return new Promise((resolve,reject)=>{
    axios.post(url, body, {headers: {'Content-Type': 'application/json',}}).then(resp => {
      if (resp.data.code == 0) {
        resolve(resp.data)
      } else {
        reject(resp.data)
      }
    }).catch (()=> {
      reject({code:400, message:'网络访问失败',data:0})
    })
  })
}

function get(uri:string, params:any){
  const url = ApiBase + uri
  return new Promise((resolve, reject)=>{
    axios.get(url, {params:params}).then(resp=>{
      if (resp.data.code == 0) {
        resolve(resp.data)
      } else {
        reject(resp.data)
      }
    }).catch(()=>{
      reject({code:400, message:'网络访问失败',data:0})
    })
  })
}

export {
  credentialsPost,
  post,
  get
}
