<template>
  <div class="myfooter"><span style="font-size:14px;">浙ICP备2020037653号 &nbsp; &nbsp; &nbsp; &copy;  直接科技</span> </div>
</template>

<style scoped>
.myfooter {
  height:"40px";
  
  background-color: #909399;
  color: blue;
  text-align: center;
}

</style>