import { credentialsPost,post} from '../request'

/* eslint-disable */

function apiRaceListAny(data:any){
  return credentialsPost('/raceapi/anyList', data)
}

function apiRaceListActive(data:any){
  return post('/raceapi/activeList', data)
}

function apiRaceGet(data:any) {
  return post('/raceapi/byId', data)
}

function apiRaceAdd(data:any) {
  return credentialsPost('/raceapi/add', data)
}

function apiRaceRemove(data:any) {
  return credentialsPost('/raceapi/remove', data)
}

function apiRaceUpdate(data:any) {
  return credentialsPost('/raceapi/update', data)
}

function apiRaceAddFile (data:any) {
  return credentialsPost('/raceapi/file/add', data)
}

function apiRaceUpdateFile (data:any) {
  return credentialsPost('/raceapi/file/update', data)
}

function apiRaceRemoveFile (data:any) {
  return credentialsPost('/raceapi/file/remove', data)
}

function apiRaceListFile (data:any) {
  return post('/raceapi/file/list', data)
}

function apiRaceGetFile (data:any) {
  return post('/raceapi/file/get', data)
}

function apiRaceMovedown (data:any) {
  return credentialsPost('/raceapi/movedown', data)
}

function apiRaceMoveup (data:any) {
  return credentialsPost('/raceapi/moveup', data)
}

export {
  apiRaceListAny,
  apiRaceListActive,
  apiRaceGet,
  apiRaceAdd,
  apiRaceRemove,
  apiRaceUpdate,
  apiRaceAddFile,
  apiRaceUpdateFile,
  apiRaceRemoveFile,
  apiRaceListFile,
  apiRaceGetFile,
  apiRaceMovedown,
  apiRaceMoveup
}
