import { credentialsPost,post } from '../request';

/* eslint-disable */

function apiUserLogin(data:any){
  return post("/userapi/login",data)
}


function apiUserRegister(data:any){
  return post("/userapi/register",data)
}

function apiUserLogout(data:any){
  return credentialsPost("/userapi/logout",data)
}


function apiUserUploadImg(data:any){
  return credentialsPost("/userapi/uploadImg",data)
}

function apiUserUpdate(data:any){
  return credentialsPost("/userapi/update",data)
}

function apiUserChangePassword(data:any){
  return credentialsPost("/userapi/changePassword",data)
}

function apiUserRemove(data:any){
  return credentialsPost("/userapi/remove",data)
}

function apiUserList(data:any){
  return credentialsPost("/userapi/listUser",data)
}


function apiUserRoleList(data:any){
  return credentialsPost("/userapi/listRole",data)
}

function apiUserVerify(data:any){
  return credentialsPost("/userapi/verify",data)
}

function apiUserGet(data:any){
  return credentialsPost("/userapi/listOne",data)
}

function apiUserImport(data:any){
  return credentialsPost("/userapi/import",data)
}


function apiUserListOrg(data:any){
  return credentialsPost("/userapi/list/org",data)
}

function apiUserRefreshToken(data:any){
  return credentialsPost("/userapi/refreshToken",data)
}

function apiUserListUnchecked (data:any) {
  return credentialsPost('/userapi/listUnchecked', data)
}

function apiUserCountUnchecked (data:any) {
  return post('/userapi/cert/countUnchecked', data)
}

function apiUserCheck(data:any) {
  return credentialsPost('/userapi/checked', data)
}

function apiUserListCredit(data:any) {
  return credentialsPost('/userapi/listCredit', data)
}


export {
  apiUserLogin,
  apiUserRegister,
  apiUserLogout,
  apiUserUploadImg,
  apiUserChangePassword,
  apiUserUpdate,
  apiUserRemove,
  apiUserList,
  apiUserRoleList,
  apiUserVerify,
  apiUserGet,
  apiUserImport,
  apiUserListOrg,
  apiUserRefreshToken,
  apiUserListUnchecked,
  apiUserCountUnchecked,
  apiUserCheck,
  apiUserListCredit
}
