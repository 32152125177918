import { defineStore } from "pinia"
import { IUser } from "../components/User/UserTypes"

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      id:0,
      username:'',
      name: '',
      token:'',
      mobi:"",
      role:0,
      permissions:[] as string [],
    };
  },
  getters :{
    isLogin: (state) => state.id != 0,
    user: (state) => {return {name:state.name,id:state.id}},
    hasPerm: (state) => {
      return (perm:string) => state.permissions.indexOf(perm) != -1
    },
    inRoles: (state) => {
      return (roles:number []) => roles.indexOf(state.role) != -1
    }
  },
  actions : {
    userInit() {
      this.token=localStorage.getItem('token') || ""
      if (this.token.length == 0) {
        this.logout()
      }
      this.id=parseInt(localStorage.getItem("id") || "0")
      this.role=parseInt(localStorage.getItem("role") || "0")
      this.name=localStorage.getItem("name") || ""
      this.username=localStorage.getItem("username") || ""
      this.mobi=localStorage.getItem("mobi") || ""
      const perm:string = localStorage.getItem("permissions") || "[]"
      this.permissions=JSON.parse(perm)
    },
    logout() {
      localStorage.removeItem('id')
      localStorage.removeItem('role')
      localStorage.removeItem('name')
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      localStorage.removeItem("permissions")
      localStorage.removeItem("mobi")
      this.id=0
      this.role=0
      this.name=""
      this.username=""
      this.mobi=""
      this.token=""
      this.permissions=[]
    },
    login(user:IUser) {
      localStorage.setItem("id", JSON.stringify(user.id))
      localStorage.setItem("role", JSON.stringify(user.role))
      localStorage.setItem("name", user.surname)
      localStorage.setItem("username", user.username)
      localStorage.setItem('token', user.token)
      localStorage.setItem('mobi', user.mobi)
      localStorage.setItem("permissions", JSON.stringify(user.permissions))
      this.id=user.id
      this.role=user.role
      this.name=user.surname
      this.username=user.username
      this.mobi=user.mobi
      this.token=user.token
      this.permissions=user.permissions
    },
  }
})

