import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import HomeView from '../views/HomeView.vue'
const RaceEnroll = () => import('../components/Race/RaceEnroll.vue')
const BrochureOrgList = () => import('../components/Brochure/BrochureOrgList.vue')
const MyOrg = () => import('../components/Org/MyOrg.vue')
const RaceLayout  = () => import('../components/Race/RaceLayout.vue')
const OrgPlayer  = () => import('../components/Org/OrgPlayer.vue')
const OrgBasic  = () => import('../components/Org/OrgBasic.vue')

const UserEditor  = () => import('../components/User/UserEditor.vue')
const UserLogin  = () => import('../components/User/UserLogin.vue')
const UserRegister  = () => import('../components/User/UserRegister.vue')
const UserLogout  = () => import('../components/User/UserLogout.vue')
const UserProfile  = () => import('../components/User/UserProfile.vue')
const AdminUser  = () => import('../components/User/AdminUser.vue')
const MyProfile = () => import('../components/User/MyProfile.vue')
const AdminRole  = () => import('../components/User/AdminRole.vue')
const AdminLayout  = () => import('../components/Admin/AdminLayout.vue')
const AdminVersion = () => import('../components/Admin/AdminVersion.vue')
const AdminDistrict  = () => import('../components/District/AdminDistrict.vue')

const AdminRacePerms  = () => import('../components/Admin/AdminRacePerms.vue')
const RaceSupport  = () => import('../components/Support/RaceSupport.vue')
const AdminRace  = () => import('../components/Race/AdminRace.vue')

const RaceCertType  = () => import('../components/Credit/RaceCertType.vue')
const RaceCertTarget  = () => import('../components/Credit/RaceCertTarget.vue')

const AdminCertTemplDesign = () => import('../components/Cert/AdminCertTemplDesign.vue')
const AdminCertTemplParam = () => import('../components/Cert/AdminCertTemplParam.vue')

const RaceEditor  = () => import('../components/Race/RaceEditor.vue')
const AdminAdmission  = () => import('../components/Admission/AdminAdmission.vue')
const EventEdit  = () => import('../components/Event/EventEdit.vue')
const EventLabel  = () => import('../components/Event/EventLabel.vue')
const EventWeight  = () => import('../components/Event/EventWeight.vue')

const AdminArena  = () => import('../components/Arena/AdminArena.vue')
const AdminGroup  = () => import('../components/Group/AdminGroup.vue')
const AdminGrade  = () => import('../components/Credit/AdminGrade.vue')
const AdminMember  = () => import('../components/Credit/AdminMember.vue')
const AdminReview  = () => import('../components/Credit/AdminReview.vue')
const AdminCreditType  = () => import('../components/Credit/AdminCreditType.vue')
const AdminCreditLib  = () => import('../components/Credit/AdminCreditLib.vue')
const AdminDevice  = () => import('../components/Device/AdminDevice.vue')

const BrochureEventIndex  = () => import('../components/Brochure/BrochureEventIndex.vue')
const BrochureClzSequence  = () => import('../components/Brochure/BrochureClzSequence.vue')

const TeamService  = () => import('../components/ItService/TeamService.vue')
const EventService  = () => import('../components/ItService/EventService.vue')
const AroundService  = () => import('../components/ItService/AroundService.vue')
const SpecialAward  = () => import('../components/ItService/SpecialAward.vue')

const AdminOrg  = () => import('../components/Org/AdminOrg.vue')
const OrgOne  = () => import('../components/Org/OrgOne.vue')
const OrgFee  = () => import('../components/Org/OrgFee.vue')
const OrgSum  = () => import('../components/Org/OrgSum.vue')

const AdminWeConfig  = () => import('../components/Wechat/AdminWeConfig.vue')
const AdminFilestore  = () => import('../components/Filestore/AdminFilestore.vue')
const StatisLayout  = () => import('../components/Statistics/StatisLayout.vue')
const StatisReferee  = () => import('../components/Statistics/StatisReferee.vue')
const StatisPlayer  = () => import('../components/Statistics/StatisPlayer.vue')
const StatisCert  = () => import('../components/Statistics/StatisCert.vue')
const StatisFee  = () => import('../components/Statistics/StatisFee.vue')
const StatisDossal  = () => import('../components/Statistics/StatisDossal.vue')
const StatisOrg  = () => import('../components/Statistics/StatisOrg.vue')
const StatisCalendar  = () => import('../components/Statistics/StatisCalendar.vue')
const StatisMedal  = () => import('../components/Statistics/StatisMedal.vue')
const StatisPlayerDetail  = () => import('../components/Statistics/StatisPlayerDetail.vue')
const StatisGroup  = () => import('../components/Statistics/StatisGroup.vue')
const StatisEvent  = () => import('../components/Statistics/StatisEvent.vue')
const StatisTicket  = () => import('../components/Statistics/StatisTicket.vue')
const StatisSupport  = () => import('../components/Statistics/StatisSupport.vue')

const SchedGroup  = () => import('../components/Schedule/SchedGroup.vue')
const SchedArena  = () => import('../components/Schedule/SchedArena.vue')
const SchedOrg  = () => import('../components/Schedule/SchedOrg.vue')
const SchedLayout  = () => import('../components/Schedule/SchedLayout.vue')

const RaceFile = () => import('../components/Race/RaceFile.vue')
const WechatLogin = () => import('../components/Wechat/WechatLogin.vue')

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/home'},
  { path: '/home', name: 'home',  component: HomeView},
  { path: '/home/:raceId', name: 'raceEnroll', component: RaceEnroll, props:true,},
  { path: '/race/:raceId', name: 'myOrg', component: MyOrg, props:true,meta: { private: true },},
  { path: '/org/:orgId', name: 'raceLayout', component: RaceLayout, props: true, meta: { private: true },
    children:[
      {path: 'basic', name: 'orgBasic', component: OrgBasic, props: true, meta: { private: true },},  
      {path: 'player', name: 'orgPlayer', component: OrgPlayer, props: true, meta: { private: true },},  
      {path: 'one', name: 'orgOne', component: OrgOne, props: true, meta: { private: true },}, 
      {path: 'fee', name: 'orgFee', component: OrgFee, props: true, meta: { private: true },}, 
      {path: 'sum', name: 'orgSum', component: OrgSum, props: true, meta: { private: true },}, 
    ]
  },

  { path: '/user/profile', name: 'userProfile', component: UserProfile,meta: { private: true }},
  { path: '/user/login', name: 'userLogin', component: UserLogin},
  { path: '/user/register', name: 'userRegister', component: UserRegister},
  { path: '/user/logout', name: 'userLogout', component: UserLogout},
  { path: '/user/my', name: 'myProfile', component: MyProfile, meta: { private: true }},
  { path: '/admin/user', name: 'adminUser', component: AdminUser,meta: { private: true }},
  { path: '/admin/user/:userId', name: 'userEditor', component:UserEditor, props:true, meta: { private: true }},
  { path: '/admin/role', name: 'adminRole', component: AdminRole,meta: { private: true }},
  { path: '/admin/grade', name: 'adminGrade', component: AdminGrade,meta: { private: true }},
  { path: '/admin/member', name: 'adminMember', component: AdminMember,meta: { private: true }},
  { path: '/admin/creditType', name: 'adminCreditType', component: AdminCreditType,meta: { private: true }},
  { path: '/admin/creditLib', name: 'adminCreditLib', component: AdminCreditLib,meta: { private: true }},
  { path: '/admin/district', name: 'adminDistrict', component: AdminDistrict,meta: { private: true }},
  { path: '/admin/weConfig', name: 'adminWeConfig', component: AdminWeConfig},
  { path: '/admin/racePerms/:raceId', name: 'adminRacePerms', component: AdminRacePerms, props:true, meta: { private: true }},
  { path: '/admin/race', name: 'adminRace', component: AdminRace,meta: { private: true }},
  { path: '/admin/device', name: 'adminDevice', component: AdminDevice,meta: { private: true }},
  { path: '/admin/version', name: 'adminVersion', component: AdminVersion,meta: { private: true }},
  { path: '/admin/filestore', name: 'adminFilestore', component: AdminFilestore,meta: { private: true }},
  { path: '/admin/statis/:raceId', name: 'statisLayout', component: StatisLayout, props:true,meta: { private: true },
    children :[
      {path: 'group', name: 'statisGroup', component: StatisGroup, props:true},
      {path: 'playerDetail', name: 'statisPlayerDetail', component: StatisPlayerDetail, props:true},
      {path: 'event', name: 'statisEvent', component: StatisEvent, props:true},
      {path: 'referee', name: 'statisReferee', component: StatisReferee, props:true},
      {path: 'player', name: 'statisPlayer', component: StatisPlayer, props:true},
      {path: 'cert', name: 'statisCert', component: StatisCert, props:true},
      {path: 'fee', name: 'statisFee', component: StatisFee, props:true},
      {path: 'dossal', name: 'statisDossal', component: StatisDossal, props:true},
      {path: 'org', name: 'statisOrg', component: StatisOrg, props:true},
      {path: 'calendar', name: 'statisCalendar', component: StatisCalendar, props:true},
      {path: 'medal', name: 'statisMedal', component: StatisMedal, props:true},
      {path: 'ticket', name: 'statisTicket', component: StatisTicket, props:true},
      {path: 'support', name: 'statisSupport', component: StatisSupport, props:true},
    ]
  },

  { path: '/admin/file/:raceId', name: 'raceFile', component: RaceFile, props:true,meta: { private: true },},

  { path: '/admin/org/:raceId', name: 'adminOrg', component: AdminOrg, props:true,meta: { private: true },},
  { path: '/admin/review/:raceId', name: 'adminReview', component: AdminReview, props:true, meta: { private: true }},
  { path: '/wechat/login', name: 'wechatLogin', component: WechatLogin, props:false,meta: { private: false,wechat:true },},
  
  { path: '/admin/race/:raceId', name: 'adminLayout',  component: AdminLayout, props:true,meta: { private: true }, /**redirect:'/admin/race/:raceId/editor', */
    children: [
      {path: 'editor', name: 'raceEditor', component: RaceEditor, props:true},
      {path: 'group', name: 'adminGroup', component: AdminGroup, props:true},
      {path: 'brochureOrg',name:"brochureOrgList", component: BrochureOrgList, props:true},
      {path: 'admission',name:"adminAdmission", component: AdminAdmission, props:true},
      {path: 'eventEdit',name:"eventEdit", component: EventEdit, props:true},
      {path: 'eventLabel',name:"eventLabel", component: EventLabel, props:true},
      {path: 'eventWeight',name:"eventWeight", component: EventWeight, props:true},

      {path: 'support', name: 'raceSupport', component: RaceSupport, props:true},
      {path: 'certType', name: 'raceCertType', component: RaceCertType, props:true},
      {path: 'certTarget', name: 'raceCertTarget', component: RaceCertTarget, props:true},
      
      {path: 'certTemplDesign', name: 'certTemplDesign', component: AdminCertTemplDesign, props:true},
      {path: 'certTemplParam', name: 'certTemplParam', component: AdminCertTemplParam, props:true},

      {path: 'suitStatis', name: 'suitStatis', component: StatisEvent, props:true},
      {path: 'groupStatis', name: 'groupStatis', component: StatisGroup, props:true},
      
      {path: 'arena', name: 'adminArena', component: AdminArena, props:true},
      {path: 'schedGroup', name:'schedGroup', component: SchedGroup, props:true, },
      {path: 'schedArena', name:'schedArena', component: SchedArena, props:true, },
      {path: 'schedOrg', name:'schedOrg', component: SchedOrg, props:true, },
      {path: 'schedLayout', name:'schedLayout', component: SchedLayout, props:true, },

      {path: 'brochureEvent', name:'brochureEventIndex', component: BrochureEventIndex, props:true, },
      {path: 'brochureClz', name:'brochureClzSequence', component: BrochureClzSequence, props:true, },
      
      
      {path: 'teamService', name:'teamService', component: TeamService, props:true, },
      {path: 'eventService', name:'eventService', component: EventService, props:true, },
      {path: 'aroundService', name:'aroundService', component: AroundService, props:true, },
      {path: 'specialAward', name:'specialAward', component: SpecialAward, props:true, },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, _, next) => {
  const token:string = localStorage.getItem("token") || ""
  if (to.matched.some(r => r.meta.private) && token.length == 0) {
    next({name: 'userLogin'})
  } else { 
  next()
  }
})

export default router
