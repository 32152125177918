export const cutways = ["按名次","按比例","不录取"]
export const raceScopes=[
  "无", 
  "单练项目",
  "对练项目",
  "集体项目",
  "对抗项目",
  "对抗展示"
]

export const raceAssist = [
  "无",
  "徒手",
  "器械"
]


export const genders = [
  {value:0,name:''},
  {value:1,name:'男'},
  {value:2,name:'女'}
]

export const currencies = [
  {value:0, name : ''},
  {value:1, name : '人民币'},
  {value:2, name : '港币'},
  {value:3, name : '美元'},
]

export const PageSize = 12

export const ticketStatus = [
  {name:"未检",value:0},
  {name:"已检",value:1},
  {name:"弃权",value:2},
  {name:"保留",value:3},
]

export const playerRoles = [
  {value:1, name:"领队"},
  {value:2, name:"教练"},
  {value:4, name:"运动员"},
  {value:8, name:"陪练"},
  {value:16, name:"裁判"},
  {value:256, name:"随队"}
]

export const roleGroups = [
  1 + 2 + 4 + 8,
  16,
  256
]

export const feeScopes = [ 
  {value:1, name: "按人头收费"},
  {value:2, name: "单练项目收费"},
  {value:3, name: "对练项目收费"},
  {value:4, name: "集体项目收费"},
  {value:5, name: "对抗项目收费"},
  {value:6, name: "对抗展示项目收费"}
]
